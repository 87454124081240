import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button,
    TextField,
    Grid,
    CardHeader,
    Paper,
    InputBase,
    Divider,
    IconButton,
    Tooltip} from '@material-ui/core';
import { colors } from '@material-ui/core';
import {Search, HighlightOff} from '@material-ui/icons';


class SimpleSearchToolbar extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.state = {
            searchKeyword: '',
        }
    }
      
    onSubmit( isReset ) {
        if( isReset ){
            this.state.searchKeyword = '';
        }
        this.props.onSubmit( this.state.searchKeyword );
    }
    
    keyPress(e){
      if(e.keyCode === 13){
         this.onSubmit( false );
      }
   }


    render() {
    return (
      <div style={{width:'60%'}} >
          <Grid container justify="flex-start"  alignItems='flex-start' spacing={2} >
              <Grid item md={12} xs={12} >
                  <Paper style={{padding: '2px 4px',display: 'flex',alignItems: 'center',width: '100%'}}>
                        {!this.state.searchKeyword && (
                            <IconButton color="secondary" style={{padding: 10}} aria-label="directions">
                              <Search />
                            </IconButton>
                        )}
                      <InputBase
                        autoFocus
                        style={{marginLeft: 4,flex: 1, width:'80%'}} size="small"
                        placeholder="Search"
                        onChange={ ( event ) => { this.setState( {searchKeyword: event.target.value }) } }
                          value={this.state.searchKeyword} onKeyDown={this.keyPress} />

                      {this.state.searchKeyword && (
                          <Divider style={{height: 28,margin: 4,}} orientation="vertical" />
                      )}

                      {this.state.searchKeyword && (
                          <Tooltip title="Close" arrow>
                              <IconButton color="primary" style={{padding: 10}} aria-label="directions">
                              <HighlightOff onClick={event => this.onSubmit(true)} />
                            </IconButton>
                          </Tooltip>
                      )}

                  </Paper>
              </Grid>
          </Grid>
      </div>
    );
    }
}
SimpleSearchToolbar.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

export default SimpleSearchToolbar;
